import { navigate } from '@reach/router';

const redirectUrl =
  'https://seller.pintuna.com/square/?utm_source=square&utm_medium=referral&utm_campaign=square-marketplace';

const Square = () => {
  navigate(redirectUrl, { replace: true });
  return null;
};
export default Square;
